import PropTypes from 'prop-types'

import css from '@styled-system/css'
import styled from '@emotion/styled'

function getFontColor(props) {
  if (props.fontColor) {
    return props.fontColor
  }

  return 'base-gray'
}

export const FieldLabel = styled.label`
  ${props =>
    css({
      fontSize: 1,
      lineHeight: '18px',
      fontWeight: '400',
      py: '0.35em',
      color: getFontColor(props),
    })}
  display: block;
`

FieldLabel.propTypes = {
  fontColor: PropTypes.string,
  disabled: PropTypes.bool,
}
